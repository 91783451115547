import React from 'react';
import { useLocation } from '@reach/router';
import Main from '../components/Warranty/Main';
import Header from '../components/Warranty/Header';
import Footer from '../components/Footer';
import useCountryLocale from '../hooks/use-country-locale';

let localeFlag = false;
let country = 'AU';
let locale = 'en-AU';

export default function FamilyClub() {
  if (!localeFlag) {
    const location = useLocation();
    if (location.pathname.indexOf('en-UK') > 0) {
      country = 'UK';
      locale = 'en-UK';
    }
    localeFlag = true;
  }

  useCountryLocale(country, locale);
  return (
    <>
      <Header />
      <Main />
      <Footer background="#232323" />
    </>
  );
}
